$color-funds-green: #a2c614;
$color-funds-red: $color-red;
$color-funds-zero: #9d9d9d;

.tfi-snippet-filter {
  @extend %paddington;
  @extend %tfi-charts;

  margin-bottom: 40px;

  &__unit-wrap {
    @include breakpoint($brake-sm) {
      width: 156px;
    }
  }

  &__unit-wrap-fund-single {
    margin-bottom: $vertical-space-mobile;

    p {
      margin: 15px 0 10px;
    }

    .select2-container--default {
      width: 60% !important;
      max-width: 250px;
    }

    @include breakpoint($brake-sm) {
      .select2-container--default {
        max-width: 120px;
      }
    }

    @include breakpoint($brake-md-lg) {
      float: left;
      box-sizing: border-box;
      padding-left: 40px;
      width: 25%;

      .select-container__label {
        @include pko-font-size(17);
      }

      p {
        margin-top: 0;
      }
    }

    @include breakpoint($brake-md-lg) {
      .select2-container--default {
        width: 100% !important;
        max-width: 100%;
      }
    }
  }

  &__period-wrap {
    @include breakpoint($brake-sm) {
      width: 260px;
      float: left;
    }
    p {
      margin-top: 0;
    }
    margin-bottom: 18px;
  }

  &__period-wrap-fund-single {
    margin-bottom: $vertical-space-mobile;

    @include breakpoint($brake-sm) {
      width: 260px;

      @include breakpoint($brake-md-lg) {
        float: left;
        box-sizing: border-box;
        width: 30%;

        .select-container__label {
          @include pko-font-size(17);
        }
      }

      @include breakpoint($brake-lg) {
        width: 35%;
        padding-left: 15px;
      }
    }

    p {
      margin: 0 0 10px;
    }
  }

  &__dates {
    @include breakpoint($brake-sm) {
      float: left;
      margin-left: 50px;
    }
  }

  &__dates-fund-single {
    margin-bottom: 20px;

    .datepicker-wrap {
      display: inline-block;
    }

    @include breakpoint($brake-md-lg) {
      width: 45%;
      float: left;
    }

    @include breakpoint($brake-lg) {
      width: 40%;
    }
  }

  &__dates-description {
    @include pko-font-size(15);
    color: $color-gray-main;
    margin: 0 0 10px;

    @include breakpoint($brake-md-lg) {
      @include pko-font-size(17);
      margin-bottom: 20px;
    }
  }

  .select-container__label {
    margin-bottom: 8px;
  }

  .datepicker-wrap {
    margin-bottom: 0;
    @include breakpoint($brake-sm) {
      margin-top: 11px;
    }
  }

  &__chart {
    margin-top: 20px;
    @include breakpoint($brake-sm) {
      height: 400px;
    }
  }

  &__checkbox-columns {
    column-count: 2;
    column-gap: 50px;
    @include breakpoint($brake-lg) {
      column-count: 3;
      column-gap: 30px;
    }
  }

  .form__filter-radio-wrap {
    display: block;
  }

  &__values {
    margin-top: $vertical-space-mobile;
  }

  &__values-box {
    box-sizing: border-box;
    float: left;
    width: 50%;
    margin-bottom: $vertical-space-mobile;

    &:nth-child(odd) {
      clear: left;
    }

    @include breakpoint($brake-md-lg) {
      margin-bottom: 20px;

      &:nth-child(odd) {
        clear: none;
      }

      &:nth-child(1) {
        width: 45%;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        width: 25%;
        padding-left: 40px;
      }
    }

    @include breakpoint($brake-lg) {
      &:nth-child(1) {
        width: 40%;
      }

      &:nth-child(2) {
        width: 35%;
        padding-left: 15px;
      }
    }
  }

  &__value-text {
    display: block;

    &--description {
      @include pko-font-size(13);

      @include breakpoint($brake-md-lg) {
        @include pko-font-size(15);
      }
    }

    &--fund-value {
      @include pko-font-size(19);
      color: $color-black;
      font-weight: 700;

      @include breakpoint($brake-md-lg) {
        @include pko-font-size(32);
        font-weight: 400;
      }
    }

    &--fund-roi-green {
      color: $color-funds-green;
    }

    &--fund-roi-zero {
      color: $color-funds-zero;
    }

    &--fund-roi-red {
      color: $color-funds-red;
    }
  }

  &__breakline-desktop {
    display: none;

    @include breakpoint($brake-md-lg) {
      display: block;
      margin-bottom: $vertical-space-desktop;
    }
  }
}
