.fund-risk {

  &__inner {
    float: left;
  }

  &:not(:first-child) {
    margin-top: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-top: $vertical-space-desktop;
    }
  }

  &:not(:last-child) {
    margin-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
    }
  }

  &__title {
    margin-bottom: 25px;
    color: $color-black;
    @include pko-text-size(17, 21, 24, 30);
  }

  &__level {
    border-radius: 50%;
    border: 1px solid $color-blue;
    color: $color-blue;
    width: 28px;
    height: 28px;
    font-size: 16px;
    text-align: center;
    padding-top: 3px;
    display: inline-block;
    font-weight: 700;
    margin-right: 10px;

    @include breakpoint($brake-xs-sm) {
      width: 40px;
      height: 40px;
      font-size: 22px;
      text-align: center;
      padding-top: 6px;
    }

    @include breakpoint($brake-md) {
      font-size: 26px;
      text-align: center;
      padding-top: 8px;
      margin-right: 15px;
      width: 50px;
      height: 50px;
    }

    // @include breakpoint($brake-md) {
    //   width: 60px;
    //   height: 60px;
    //   text-align: center;
    //   padding-top: 9px;
    // }

    &.selected {
      color: $color-white;
      background-color: $color-blue;
    }

    &:last-child {
      margin: 0;
    }

    //$fund-color-green: $color-green;
    //$fund-color-red: $color-red;
    //$size: 100 / 6;
    //
    //@for $i from 1 through 7 {
    //  &--#{$i} {
    //    $color: mix(
    //        $fund-color-red,
    //        $fund-color-green,
    //        decimal-floor($size * ($i - 1)) * 1%
    //    );
    //    border-color: $color;
    //    color: $color;
    //    background-color: rgba($color, 0.15);
    //
    //    &.selected {
    //      background-color: $color;
    //    }
    //  }
    //}
  }

  &__labels {
    margin: 10px 0 30px;
  }

  &__label {
    @extend %text-default-3;
    &:first-child {
      float: left;
    }
    &:last-child {
      float: right;
      text-align: right;
    }
  }
}

.fund-time {
  clear: left;

  &:not(:first-child) {
    margin-top: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-top: $vertical-space-desktop;
    }
  }

  &:not(:last-child) {
    margin-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
    }
  }

  &__title {
    color: $color-black;
    @include pko-text-size(17, 21, 24, 30);
    display: block;
  }

  &__value {
    color: $color-white;
    display: inline-block;
    text-align: center;
    background: $color-blue;
    font-weight: 400;
    padding: 8px 25px;
    @include pko-text-size(17, 21, 24, 30);
    border-radius: 34px;
    margin: 15px 0 0;

    @include breakpoint($brake-sm) {
      padding: 8px 50px;
    }

    span {
      font-weight: 700;
    }
  }
}

.fund-chart {
  &:not(:first-child) {
    margin-top: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-top: $vertical-space-desktop;
    }
  }

  &:not(:last-child) {
    margin-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
    }
  }

  .tfi-snippet-filter__values-box {
    .icon-infotip {
      margin-left: 18px;
      
      &::before {
        content: $icon-infotip !important;
      }
    }
  }
}

%fund-table {
  border-left: 0;
  border-right: 0;
  th {
    color: $color-black;
  }

  td,
  th {
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
    @extend %text-default-2;
    padding: 12px 8px;
    @include breakpoint($brake-sm) {
      padding: 12px 20px;
    }
  }
}

.fund-roi-table {
  $root: &;

  position: relative;

  &:not(:first-child) {
    margin-top: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      // odległość równa odległości linii guzika 'kup fundusz'
      margin-top: 30px;
    }
  }

  &:not(:last-child) {
    margin-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
    }
  }
  &__date {
    margin-bottom: 8px;
    @extend %text-default-2;
  }
  .table-wrapper {
    margin-top: 0;
  }
  // nadpisywanie styli .text:not(BS) table
  @at-root #{$root}.text table#{$root}__data {
    @extend %fund-table;
    td,
    th {
      text-align: left;
    }
  }
}

.fund .text table:not(.clean-table) {
  @extend %fund-table;
}

.fund-cta {
  width: 100%;
  height: 76px;
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  @include pko-z-index('fixed-paginator');
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid $color-gray-fifth;

  @include breakpoint($brake-sm) {
    height: auto;
    position: static;
    border: none;
  }

  & > p {
    width: 100%; //wyswig cta container
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cta {
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include pko-text-size($font-size-h3-mobile, $line-height-h3-mobile, $font-size-h3, $line-height-h3);
    overflow: visible;
    width: 259px;

    @include breakpoint($brake-sm) {
      position: relative;
      height: 64px;
      width: 100%;
      margin-bottom: 30px;
    }

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: $color-gray-fifth;
      top: 64px + 30px; //button height + margin
      left: 0;
      display: none;
      @include breakpoint($brake-sm) {
        display: block;
      }
    }
  }
}

.fund__call-me {
  width: 100%;
  height: 108px;
  &:not(:first-child) {
    margin-top: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-top: $vertical-space-desktop;
    }
  }

  &:not(:last-child) {
    margin-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
    }
  }

  border-top: 1px solid $color-gray-fifth;
  border-bottom: 1px solid $color-gray-fifth;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__text {
    color: $color-gray-second;
    font-size: 20px;
  }

  &__number {
    @include pko-text-size($font-size-h3-mobile, $line-height-h3-mobile, $font-size-h3, $line-height-h3);
  }
}

.snippet-wysiwyg:not(:first-child).fund-list {
  margin-top: 0;
  margin-bottom: 45px;

  h3 {
    margin-bottom: 24px;
  }

  ul {
    margin: 0;
  }
}

.fund-comment {
  h5 {
    font-weight: 700;
    margin: 0;
  }

  h3 {
    margin-bottom: 24px;
  }

  & > p {

    &:first-of-type {
      font-weight: 700;
      margin-bottom: 10px;
    }

    margin-top: 5px;
  }
}

body.tfi-fixed-fund-mobile footer {
  padding-bottom: 120px;

  @include breakpoint($brake-sm) {
    padding-bottom: 0;
  }
}

.fund-pie {
  &:not(:first-child) {
    margin-top: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-top: $vertical-space-desktop;
    }
  }

  &:not(:last-child) {
    margin-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
    }
  }

  &__header {
    color: $color-black;
    @include pko-text-size(17, 21, 24, 30);
    //margin-bottom: $vertical-space-mobile;
    margin-bottom: 0;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
    }
  }

  &__text {
    margin-top: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-top: $vertical-space-desktop;
    }
  }
}

.fund__additional {
  clear: both;
  top: $vertical-space-mobile;
  @include breakpoint($brake-sm) {
    top: $vertical-space-desktop;
  }
}
