#investment-funds {
  .funds-timeline {
    max-width: 360px;
    margin-bottom: 30px;

    @include breakpoint($brake-sm) {
      max-width: 263px;
      margin-bottom: 50px;
    }
    &__label {
      display: block;
      margin-bottom: 10px;
    }
  }

  .ajax-results {
    min-height: 200px;
  }
}

// TODO: Dodanie zmiennych do odpowiednich miejsc w strukturze .scss
// Zmienne tymczasowo tutaj
$color-background-table: #f2f2f2;
$color-funds-green: #a2c614;
$color-funds-red: #e4202c;
$color-funds-zero: #9D9D9D;
$color-funds-disabled: #c8c8c8;
$color-funds-date: #bbbbbb;
$text-bold-weight: 700;
$border-bottom-funds: 1px solid $color-gray-fifth;
$box-shadow-funds-top: 0 6px 19px -9px rgba(0, 0, 0, 0.40) inset;
$box-shadow-funds-bottom: 0 -6px 19px -9px rgba(0, 0, 0, 0.24) inset;
// Zmienne tymczasowo tutaj

.funds-h2 {
  display: none;
  @include breakpoint($brake-sm) {
    display: block;
    float: right;
    position: relative;
    bottom: 23px;
  }
}

// TODO: Dodanie fixów dla niektórych rozdzielczości dotyczących wyświetlania się ikon sortowania oraz łamania tekstu w tabeli
.fundsValue {
  @include pko-line-height($font-size-t3-mobile, $line-height-t3-mobile);

  .disabled {
    color: $color-funds-disabled;
    cursor: not-allowed;
  }

  .icon-arrow-down,
  .icon-arrow-top {
    position: relative;
    top: 2px;
    color: $color-blue;
    font-weight: $text-bold-weight;

    @include breakpoint($brake-sm) {
      display: block;
    }

    @include breakpoint($brake-lg) {
      display: inline;
      left: 8px;
    }

    &--sort {
      color: $color-gray-second;
      cursor: pointer;
      left: 0;
      margin-right: 0;
      &.active {
        color: $color-blue;
      }
    }

    &--disabled {
      color: $color-funds-disabled;
    }
  }

  .openFundsIcons {
    color: $color-blue;
    font-size: 22px;

    @include breakpoint($brake-sm) {
      font-size: 34px;
    }
  }

  .icon-chart {
    position: relative;
    top: 3px;
  }

  .icon-umbrella {
    position: relative;
    left: 5px;
    font-size: 13px;

    @include breakpoint($brake-sm) {
      top: 3px;
      font-size: 16px;
    }
  }

  .icon-leaf {
    display: inline-block;
    width: auto;
    height: 1em;
    margin-left: 7px;
    position: relative;
    top: 2px;

    @include breakpoint($brake-sm) {
      top: 2px;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .textAfterIcon {
    color: $color-gray-main;

    @include breakpoint($brake-sm) {
      display: none;
    }

    @include breakpoint($brake-xl) {
      display: initial;
      position: relative;
      bottom: 6px;
    }
  }

  // Kolory dla wartości dodatnich, ujemnych oraz zerowych w funduszach
  .greenColor {
    color: $color-funds-green;
  }

  .redColor {
    color: $color-funds-red;
  }

  .zeroColor {
    color: $color-funds-zero;
  }

  .showFundDetails {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__title {
    padding-bottom: 12px;
    border-bottom: $border-bottom-funds;
    @include pko-font-size($font-size-t2-mobile);
    @include pko-line-height($font-size-t2-mobile, $line-height-t2-mobile);

    table {
      width: 100%;
    }
  }

  &__disclaimer {
    margin-top: 20px;
    margin-bottom: 40px;
    @include pko-font-size($font-size-t2-mobile);
    @include pko-line-height($font-size-t2-mobile, $line-height-t2-mobile);

    @include breakpoint($brake-sm) {
      margin-top: 30px;
      max-width: 80%;
      @include pko-font-size($font-size-t1-mobile);
    }

    @include breakpoint($brake-md) {
      margin-top: 40px;
      max-width: 65%;
      @include pko-font-size($font-size-t1);
    }
  }

  &__fundIdentity {
    padding: 12px 0;
    border-bottom: $border-bottom-funds;
    @include pko-font-size($font-size-t3-mobile);

    @include breakpoint($brake-sm) {
      padding: 0;
      border-bottom: initial;
      @include pko-font-size($font-size-t3);
    }

    table {
      width: 100%;
    }

    &--fundName {
      float: left;
      max-width: 180px;

      @include breakpoint($brake-xs-sm) {
        max-width: none;
      }
    }

    &--fundDetails {
      float: right;
    }
  }

  &__fundData {
    @include pko-give-margin(30px);
    padding: 17px 30px;
    @include pko-font-size($font-size-t3-mobile);
    background-color: $color-background-table;
    box-shadow: $box-shadow-funds-top, $box-shadow-funds-bottom;

    // Fix wyświetlania się przy otwartych detalach funduszu border-bottom elementu fundIdentity
    transform: translateY(-1px);

    @include breakpoint($brake-sm) {
      margin: 0;
      padding: 30px 0 0 0;
      background: none;
      box-shadow: none;

      & > div > div:nth-child(odd) .fundsValue__fundIdentity {
        background-color: $color-background-table;
      }
    }

    @include breakpoint($brake-md) {
      padding: 5px 0 0 0;
    }

    table {
      width: 100%;

      @include breakpoint($brake-sm) {
        display: table;
        border-spacing: 0;
        border-collapse: collapse;

        td {
          padding: 14px 12px;
          box-shadow: 1px 0 0 0 $color-gray-fifth, 0 1px 0 0 $color-gray-fifth, 1px 1px 0 0 $color-gray-fifth, 1px 0 0 0 $color-gray-fifth inset, 0 1px 0 0 $color-gray-fifth inset;
          @include pko-font-size($font-size-t3);
          @include pko-line-height($font-size-t3, $line-height-t3-mobile);
        }

        .noneBorderLeft {
          box-shadow: 0 0 0 0 $color-gray-fifth, 0 1px 0 0 $color-gray-fifth, 0 1px 0 0 $color-gray-fifth, 0 0 0 0 $color-gray-fifth inset, 0 1px 0 0 $color-gray-fifth inset;
        }

        .noneBorderRight {
          box-shadow: 0 0 0 0 $color-gray-fifth, 0 1px 0 0 $color-gray-fifth, 0 1px 0 0 $color-gray-fifth, 1px 0 0 0 $color-gray-fifth inset, 0 1px 0 0 $color-gray-fifth inset;
        }

        .noneBorder {
          box-shadow: none;
        }

        .backgroundGrey {
          background-color: $color-background-table;
        }

        .dateDetails {
          @include pko-font-size($font-size-t3-mobile);
          color: $color-funds-date;
        }
      }

      @include breakpoint($brake-md) {
        td {
          @include pko-font-size($font-size-t2);
        }
      }
    }

    th {
      color: $text-color-bold;

      @include breakpoint($brake-sm) {
        padding: 14px 12px;
        box-shadow: 1px 0 0 0 $color-gray-fifth, 0 1px 0 0 $color-gray-fifth, 1px 1px 0 0 $color-gray-fifth, 1px 0 0 0 $color-gray-fifth inset, 0 1px 0 0 $color-gray-fifth inset;
        @include pko-font-size($font-size-t3);
        @include pko-line-height($font-size-t3, $line-height-t3-mobile);
      }

      @include breakpoint($brake-md) {
        @include pko-font-size($font-size-t2);
      }
    }

    &--header {
      padding-bottom: 6px;
      border-bottom: $border-bottom-funds;

      th {
        padding-bottom: 15px;
        font-weight: $text-bold-weight;
      }

      &.secondDataHeaderOpenFunds {
        padding-top: 10px;
      }
    }

    &--main {
      border-bottom: $border-bottom-funds;

      th,
      td {
        @include pko-line-height($font-size-t3-mobile, $line-height-t1);
      }

      &.secondDataOpenFunds {
        border-bottom: none;
      }
    }

    &--footer {
      th {
        padding: 14px 0 10px 0;
        font-weight: $text-bold-weight;
      }
    }
  }
}

.funds-files {
  margin-top: 30px;

  &__how-mobile {
    clear: left;
    margin-top: 30px;
    display: block;

    @include breakpoint($brake-md) {
      display: none;
    }
  }

  &__how-desktop {
    display: none;

    @include breakpoint($brake-md) {
      display: block;
      margin-top: 0;
      float: right;
    }
  }
}

.popup__user-verify {
  display: none;
}
