.tfi-snippet-no-filter {
  &__item {
    @extend %paddington;

    @include breakpoint($brake-sm) {
      @include span(6);
    }
  }

  &__header {
    margin-bottom: 0;
    @include breakpoint($brake-sm) {
      margin-bottom: 40px;
    }
  }

  &__date {
    margin-bottom: 3px;
    display: inline-block;
  }

  &__delta {
    position: relative;
    @include pko-rate-arrows;
    padding-right: 20px;

    &--up {
      color: $color-green;
    }
    &--down {
      color: $color-red;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    &-head {
      @include pko-text-size($font-size-t1-mobile, $line-height-t1-mobile, $font-size-h3, $line-height-h3);

      font-weight: 400;
      color: $color-black;

      @include breakpoint($brake-sm) {
        padding-bottom: 18px;
      }

    }

    td {
      line-height: 1.5;
    }

    th,
    td {
      padding-left: 12px;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  @extend %tfi-charts;
}
