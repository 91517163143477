//Kalkulatory redesign tfi

.tfi-calc {
  p {
    @include pko-font-size($font-size-t3);
    @include breakpoint($brake-sm) {
      @include pko-font-size($font-size-t1);
    }
    margin: 0 0 10px 0;
  }

  &__select-container {
    display: inline-block;
    width: 100%;
    margin: 0 30px 10px 0px;

    @include breakpoint($brake-sm) {
      max-width: 388px;
    }

    p {
      margin: 0 5px 0 0;
      display: inline-block;
    }
  }

  .results-container {
    width: 100%;
    height: 48px;
    @include breakpoint($brake-sm) {
      margin-top: 15px;
    }

    &--sum {
      @include breakpoint($brake-sm) {
        width: 87%;
      }
    }

    &__header {
      display: inline-block;
      margin: 0;
      padding-top: 5px;
      @include breakpoint($brake-sm) {
        padding-top: 10px;
      }
    }

    &--chart-labels {
      @include breakpoint($brake-sm) {
        position: absolute;
        top: 30px;
        left: 0;
      }
      &__label {
        margin-top: 13px;
        span {
          display: block;
          @include pko-font-size(17);
          font-weight: bold;
        }

        label {
          display: block;
        }

        &--top {
          margin-top: 56px;

          @include breakpoint($brake-sm) {
            margin-top: 9px;
          }

          span {
            color: #004c9a;
          }
        }
        &--bottom {
          span {
            color: #e4172c;
          }
        }
      }
    }

    &--no-border {
      display: block;
      width: 100%;
      margin-top: 20px;
      height: 43px;

      @include breakpoint($brake-sm) {
        height: auto;
        width: 50%;
        margin-top: 25px;
      }

      @include breakpoint($brake-md) {
        margin-top: 50px;
        width: 50%;
        display: inline-block;
        position: absolute;
        right: 228px;
        bottom: -35px;
        margin-top: 0;
        max-width: 209px;
      }

      &:last-of-type {
        right: 0px;
      }

      label {
        display: block;
        text-align: left;
        padding-right: 15px;
        margin-bottom: 5px;
        @include breakpoint($brake-md) {
          width: 209px;
          text-align: right;
        }
      }

      .calc-value {
        float: none;
        position: relative;
        display: block;
        background-color: #fff;
        border: none;

        @include breakpoint($brake-sm) {
          display: inline-block;
        }

        &--read-only {
          float: none;
          position: absolute;
          top: 0;
          right: -5px;
          width: 100%;
          padding: 0 25px 0 0;
          text-align: left;
          @include breakpoint($brake-md) {
            text-align: right;
            right: 0;
          }
        }
      }
    }
  }

  .irs-slider-wrapper {
    &--disabled {
      opacity: 0.5;
    }
  }

  .irs-controls {
    min-width: 260px;
    .irs-value {
      float: right;
      @include breakpoint($brake-sm) {
        width: 209px;
      }

      input:read-only {
        color: #000;
        padding: 0 25px 0 0;
      }

      input.js-pretty-number {
        width: 65%;
        padding: 0;
        @include breakpoint($brake-sm) {
          width: 73%;
        }
      }
    }
  }

  .irs-value {
    input {
      color: #000;
      height: 100%;
      &:read-only {
        padding: 0 25px 0 0;
      }
      &:disabled {
        display: block;
      }
    }
  }

  .calc-value {
    float: none;
    width: 50%;
    padding-top: 0px;
    margin-top: 7px;
    background-color: #F2F2F2;
    display: block;

    &:before {
      display: none;
    }

    @include breakpoint($brake-sm) {
      float: right;
      width: 209px;
      display: inline-block;
      margin: 0;

      &:before {
        display: block;
      }
    }
  }

  &__additional-info {
    margin-top: 50px;
    max-width: 963px;
    border-top: 1px solid #DEDEDE;
    padding-top: 25px;

    &--full-width {
      width: 100%;
      max-width: none;
    }

    &__link {
      display: block;
      font-size: 0.9375rem;
      margin-top: 14px;
    }

    &__paragraph {
      margin-top: 25px;
      font-size: 0.9375rem;

      &__header {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
  }

  &__additional-options-container {
    margin-bottom: 40px;
  }

  &__additional-options {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
    @include breakpoint($brake-sm) {
      width: 30%;
      margin-bottom: 20px;
    }

    .input-text {
      @include breakpoint($brake-sm) {
        width: 90%;
        position: relative;
      }

      input {
        &:disabled {
          @include breakpoint($brake-sm) {
            background-color: #F2F2F2;
          }
        }
        @include breakpoint($brake-sm) {
          position: absolute;
          width: 100%;
          height: 98%;
          padding-right: 35px;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &__form {
    .form__filter-fieldset {
      padding: 0 0 45px 0;

      &:first-of-type {
        margin-top: 7px;
      }
    }

    .input-radio--inline {
      display: inline-block;
      margin: 0 25px 5px 0;
    }
  }

  &__chart-container {
    position: relative;
  }

  &__rent-calc-container {
    border-top: 1px solid #DEDEDE;
    margin-top: 30px;
    @include breakpoint($brake-md) {
      margin-top: 80px;
    }
  }

  .profit-chart {
    position: relative;
    display: inline-block;
    height: 400px;
    width: 100%;

    @include breakpoint($brake-md) {
      height: 290px;
      width: 570px;
    }

    .highcharts-tick {
      display: none;
    }

    .highcharts-title {
      tspan {
        font-family: pkobp;
        font-weight: 400;
        @include pko-font-size($font-size-h4);
      }
    }

    .highcharts-container {
      overflow: visible !important;

      .highcharts-root {
        overflow: visible !important;
      }
    }

    &__label {
      position: absolute;
      z-index: 20;
      top: 420px;
      @include breakpoint($brake-md) {
        top: auto;
        bottom: -25px;
      }

      &--start {
        left: 0;
      }

      &--end {
        right: 0;
        @include breakpoint($brake-md) {
          left: 535px;
          right: auto;
        }
      }
    }
  }
}

.tfi-pension-scheme {
  .overlay {
    padding: 50px 0;
  }
  .pension-input {
    width: 33.33%;
    padding-right: 5%;
    @include breakpoint($brake-md-max) {
      width: 100%;
    }
    &-half {
      width: 50%;
      padding-right: 5%;
      @include breakpoint($brake-md-max) {
        width: 100%;
      }
    }
    &-wide {
      width: 69%;
      @include breakpoint($brake-md-max) {
        width: 100%;
      }
    }
    &-container {
      display: flex;
      margin-bottom: 100px;
      @include breakpoint($brake-md-max) {
        flex-direction: column;
      }
    }
    &--disclaimer {
      display: block;
      flex: 0 0 100%;
      margin-bottom: 35px;
      @include breakpoint($brake-sm-max) {
        flex: 1 0 100%;
      }
    }
    &--emphasize {
      font-size: 2rem;
      font-weight: bold;
      color: $color-black;
    }
  }
  .results-container__header {
    font-size: 1rem;
    margin-bottom: 10px;
    @include breakpoint($brake-md-max) {
      display: block;
    }
  }
  .calc-value {
    float: none;
    background-color: $color-white;
    &:before {
      display: inline-block;
    }
    input[type="text"] {
      width: 50%;
      @include breakpoint($brake-md-max) {
        width: 75%;
      }
      @include breakpoint($brake-sm-max) {
        width: 50%;
      }
    }
    @include breakpoint($brake-md-max) {
      width: 75%;
    }
  }
  .pension-constant-values {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint($brake-sm-max) {
      flex-direction: column;
    }
  }
  .calc__sliders {
    &--disclaimer {
      font-size: 0.875rem;
      margin-bottom: 35px;
    }
    .irs-wrapper {
      min-height: 100px;
    }
    .irs-value.input-text {
      padding-bottom: 0;
    }
    .irs-controls {
      bottom: 35px;
    }
    &--red-message {
      font-size: 0.875rem;
      color: $color-red;
      @include breakpoint($brake-sm-max) {
        font-size: 0.8125rem;
        margin: 35px 0 0;
      }
    }
    @include breakpoint($brake-sm) {
      &__list-item p {
        margin-bottom: 35px;
      }
    }
  }
  .pension-results {
    width: 33.33%;
    padding-right: 5%;
    @include breakpoint($brake-sm-max) {
      width: 100%;
    }
    &-container {
      display: flex;
      margin-bottom: 100px;
      @include breakpoint($brake-sm-max) {
        flex-direction: column;
      }
    }
    .results-container__header {
      font-size: 0.875rem;
      color: $color-gray-main;
    }
  }
}

.paymentPeriod .irs {
  &::before {
    content: '120 m-cy';
    display: inline-block;
    position: absolute;
    top: 100%;
    left: 23%;
    font-size: 0.8rem;
    color: #bababa;
  }
  &::after {
    content: '';
    width: 2px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 25%;
    background: #dedede;
  }
}

.onetimePayment .irs {
  &::before {
    content: '25%';
    display: inline-block;
    position: absolute;
    top: 100%;
    left: 23%;
    font-size: 0.8rem;
    color: #bababa;
  }
  &::after {
    content: '';
    width: 2px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 25%;
    background: #dedede;
  }
}
