.tfi-media-materials {
  .pagination {
    text-align: center;
    padding: 34px 0 0 0;
    @include breakpoint($brake-sm) {
      padding-top: 50px;
      text-align: right;
    }
  }

  .overlay {
    top: -52px;
  }
}

.video-article, .file-article {
  margin-bottom: 49px;
  &__title {
    margin: 0 0 8px 0;
  }

  &__tag {
    @extend %text-default-3;
    margin-bottom: 13px;
    color: #8d8d8d;
  }

  .files-download__list {
    padding: 0;
  }
}

.video-article {
  &__video-title {
    @extend %text-default-1;
    margin: 14px 0 23px 0;
  }

  &__video {
    height: 200px;
    @include breakpoint($brake-sm) {
      height: 360px;
    }
  }
}
